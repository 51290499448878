import Background from "./components/background";
import Cesar from "./components/cesar";

import "./scss/main.scss";

export default function App() {
  return (
    <>
      <div className="app">
        <Background></Background>
        <div className="center">
          <Cesar />
        </div>
      </div>
    </>
  );
}