
import React, { useEffect, useState } from 'react';

const OGTEXT = 'Cesarpulles.nl';
let LETTERS = OGTEXT.split('').reverse();
const DELAY = 360;

let currentIntervalId = null;

export default function Cesar() {
    const [text, setText] = useState('');
    const [animateSoon, setAnimateSoon] = useState(false);

    useEffect(() => {
        const setWidth = () => {
            const hiddenText = document.querySelector('.hidden-text');
            const hiddenTextWidth = hiddenText.clientWidth;

            document.querySelector('.spacer').style.width = `${hiddenTextWidth}px`;
        };

        const animate = () => {
            setText(text + LETTERS.pop());
        };

        if (LETTERS.length > 0) {
            currentIntervalId = setInterval(animate, DELAY);
            setWidth();

            window.addEventListener('resize', setWidth);
        }
        else {
            setAnimateSoon(true);
        }

        return () => {
            if (currentIntervalId) clearInterval(currentIntervalId);
            window.removeEventListener('resize', setWidth);
        };
    }, [text]);

    return (
        <>
            <div className="hidden-text"><span className="left">{`[ ${OGTEXT}`}</span>&nbsp;{']'}</div>
            <div className="hero">
                <div className="spacer">
                    <span className="bracket">{'['}&nbsp;</span>
                    <span className="regular-text">{text}<div className="cursor"></div></span>
                    <span className="bracket-end">&nbsp;{']'}</span>
                </div>
            </div>
            <div className={`soon-tm${animateSoon ? ' animate' : ''}`}>Coming Soon</div>
        </>
    );
}
