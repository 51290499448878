
import React, { useEffect, useState, useRef } from 'react';

import WAVES from 'vanta/dist/vanta.waves.min';

export default function Background() {
    const [effect, setEffect] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if (!effect) {
            setEffect(WAVES({ 
                el: ref.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: true,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x393E41,
                shininess: 8.00,
                waveHeight: 7.00,
                waveSpeed: 0.60,
                zoom: 0.9
            }));
        }

        return () => {
            if (effect) effect.destroy();
        };
    }, [effect]);

    return (
        <div className="background" ref={ref}></div>
    );
}
